import { InsightTypes } from '@core/insights';
import { css } from '@emotion/react';
import {
  SvgDove,
  SvgStrength,
  SvgSunny,
  SvgYoga,
} from '@web/components/svgs/insights-icons';
import { theme } from '@web/features/theme';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { useInsightsData } from './use-insights-data';

export const insightTypes: Array<{
  key: InsightTypes;
  icon: JSX.Element;
  title: string;
  text: string;
}> = [
  {
    key: 'physical',
    title: 'Physical Health',
    icon: <SvgStrength />,
    text: 'Everybody has ups and downs — you can see the emerging patterns here.',
  },
  {
    key: 'emotional',
    title: 'Emotional Health',
    icon: <SvgSunny />,
    text: 'Feelings always change over time, but some days and weeks might be better than others.',
  },
  {
    key: 'spiritual',
    title: 'Spiritual Health',
    icon: <SvgYoga />,
    text: 'What are the patterns that emerge over time as you track your connection to spirituality?',
  },
  {
    key: 'hope',
    title: 'Hope',
    icon: <SvgDove />,
    text: 'How does your hope shift over time? Here you can track the days and weeks as they go by.',
  },
];

export const getTypeTitle = (type: InsightTypes) => {
  return (
    insightTypes.find((insightType) => type === insightType.key)?.title ||
    insightTypes[0].title
  );
};

export const getTypeText = (type: InsightTypes) =>
  insightTypes.find((insightType) => type === insightType.key)?.text ||
  insightTypes[0].text;

/**
 * # InsightTypes Navigation
 * @packageDescription
 */
export function TypeNav({
  type,
  customStyles: custom,
  setType,
}: {
  type: ReturnType<typeof useInsightsData>['0']['type'];
  setType: ReturnType<typeof useInsightsData>['1']['setType'];
  customStyles: { url: string; color: string };
}) {
  return (
    <nav
      css={styles.nav}
      style={{ backgroundColor: custom.color }}
      className={`${type}`}
    >
      <AnimateSharedLayout type="crossfade">
        {insightTypes.map((localType) => (
          <motion.button
            layout
            type="button"
            key={localType.key}
            onClick={() => setType(localType.key)}
            css={css`
              ${styles.navLink}

              svg {
                fill: ${type === localType.key ? custom.color : 'auto'};
              }
            `}
            className={type === localType.key ? 'active' : 'inactive'}
          >
            {localType.icon}

            {type === localType.key && (
              <motion.span layoutId="background" className="background" />
            )}
          </motion.button>
        ))}
      </AnimateSharedLayout>
    </nav>
  );
}

const styles = {
  nav: css`
    flex: 0.1 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: ${theme.zIndices.dropdown};
    padding: ${theme.space[1]};
    background-color: ${theme.colors.whiteAlpha[600]};
    border: ${theme.borders['1px']} ${theme.colors.whiteAlpha[900]};
    border-radius: ${theme.radii.lg};
  `,

  navLink: css`
    cursor: pointer;
    position: relative;
    height: 8vw;
    width: 8vw;
    max-height: ${theme.space[10]};
    max-width: ${theme.space[10]};
    padding: 2%;
    border-radius: ${theme.radii.lg};
    background-color: transparent;
    border: none;

    &:not(:last-of-type) {
      margin-right: ${theme.space[4]};
    }

    .background {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-color: ${theme.colors.whiteAlpha[900]};
      border-radius: ${theme.radii.md};
    }

    svg {
      height: 100%;
      width: 100%;
      fill: ${theme.colors.whiteAlpha[900]};
    }
  `,
};
