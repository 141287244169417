import { css } from '@emotion/react';
import { Animation, easing, listAnimation, theme } from '@web/features/theme';

export const navAnimation: Animation = listAnimation;

export const header = css`
  --padding: ${theme.space[4]};
  --icon-size: var(--padding);
  --text-color: ${theme.colors.whiteAlpha[900]};
  --highlight-color: ${theme.colors.blackAlpha[300]};
  --border-color: ${theme.colors.whiteAlpha[600]};
  --background-color: ${theme.colors.whiteAlpha[100]};
  --background-color--backdrop: ${theme.colors.whiteAlpha[200]};
  --blur: blur(6px);
  --shadow: ${theme.shadows.lg};

  z-index: ${theme.zIndices.docked};
  position: absolute;
  top: calc(1% + env(safe-area-inset-top));
  left: 2.5%;
  width: 95%;
  display: flex;

  @media (min-width: ${theme.breakpoints[3]}) {
    left: 17%;
  }
`;

export const menuButton = css`
  cursor: pointer;
  padding: ${theme.space[2]};
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: none;

  z-index: ${theme.zIndices.overlay + 1};

  svg {
    height: ${theme.space[10]};
    width: ${theme.space[10]};
    fill: ${theme.colors.whiteAlpha[900]};
  }
`;

export const icon = css`
  display: block;
  min-height: ${theme.space[2]};
  min-width: ${theme.space[2]};
`;

export const nav = css`
  position: absolute;
  min-height: 85vh;
  width: 50vw;
  max-width: ${theme.space['64']};

  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: var(--background-color);
  box-shadow: var(--shadow);

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background-color: var(--background-color--backdrop);
    backdrop-filter: var(--blur);
  }

  &.disabled,
  &.closed {
    pointer-events: none;
  }
`;

export const navLink = css`
  width: 100%;
  position: relative;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: start;

  color: var(--text-color);
  padding: var(--padding);
  text-transform: capitalize;

  &:not(:last-child) {
    border-bottom: ${theme.borders['2px']} var(--border-color);
  }

  svg {
    width: var(--icon-size);
    height: var(--icon-size);
    fill: var(--text-color);
    margin-right: ${theme.space[2]};
  }

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;

    background-color: var(--highlight-color);
    transition: width 350ms cubic-bezier(${easing.join(',')});
  }

  &:hover,
  &:focus,
  &.active {
    &::before {
      width: 100%;
    }
  }
`;
